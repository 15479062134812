<template>
  <div class="setting-calender">
    <div class="box-setting-header ">
      <div class="title ml-4 mt-2">Calendrier</div>
      <div>
        <b-button
          variant="success"
          class="config-btn ml-2"
          v-b-modal.addCalenderModal
          ><font-awesome-icon icon="plus" /> Ajouter une année</b-button
        >
        <b-modal
          ref="addCalenderModal"
          id="addCalenderModal"
          title="Ajouter une année"
          :hide-footer="true"
          :hide-header="true"
          @hidden="resetModal()"
          modal-class="cutsom-modal-bootstrap "
        >
          <div class="hader mb-2">
            <div class="titleSetting">Ajouter une année</div>
            <div
              class="iconClose"
              @click.prevent="hideModal('addCalenderModal')"
            >
              <font-awesome-icon icon="times" />
            </div>
          </div>
          <Card>
            <template v-slot:body>
              <form
                @submit.prevent="handleSubmitCalender"
                class="form-modal-custom-style"
              >
                <b-form-group
                  label=""
                  label-for="year"
                  class=" input-modal-champ mt-4"
                >
                  <b-form-select
                    id="year"
                    v-model="calenderAdd"
                    :options="ListYear"
                    required
                    class="b-form-select-raduis "
                  ></b-form-select>
                </b-form-group>
                <div class="actionModel">
                  <div class="messageError">
                    <div v-if="error" class="error">
                      <ul v-if="Array.isArray(error)">
                        <li v-for="(e, index) in error" :key="index">
                          {{ e }}
                        </li>
                      </ul>
                      <div v-else>{{ error }}</div>
                    </div>
                  </div>
                  <b-button type="submit" class="button-valide-style mt-3">
                    <span>
                      Valider
                      <div v-if="loading" class="loading ml-2">
                        <div class="spinner-border" role="status"></div>
                      </div>
                    </span>
                  </b-button>
                </div>
              </form>
            </template>
          </Card>
        </b-modal>
      </div>
    </div>
    <div class="ligne-box-setting-header"></div>
    <div class="box-all-filter">
      <div class="box-label-champ mr-2">
        <div class="label-box-style">
          <span class="title-tabel">Année</span>
        </div>
        <b-form-select
          class="b-form-select-new-style"
          v-model="filteryear"
          :options="ListYear"
          @change="handleChangeYear"
        ></b-form-select>
      </div>
      <div v-if="getLoadingCalender" class=" chargement-message-calender ml-2">
        Chargement en cours ...
      </div>
      <div class="error-message">
        <div v-if="getErrorCalender" class="error">
          <ul v-if="Array.isArray(getErrorCalender)">
            <li v-for="(e, index) in getErrorCalender" :key="index">
              {{ e }}
            </li>
          </ul>
          <div v-else>{{ getErrorCalender }}</div>
        </div>
      </div>
    </div>
    <div class="calender-body">
      <div class="calender-card">
        <div
          v-for="(key, value) in getCalenders.months"
          :key="'mounths' + value"
          class="card-mounts"
        >
          <div>
            <div class="card-mounths-header ">{{ value | FormatMounths }}</div>
            <div class="card-mountes-body">
              <div
                class="card-mounths-item"
                v-for="(item, index) in key"
                :key="item"
              >
                <div>
                  <font-awesome-icon
                    v-if="index == 0 && value != 1"
                    icon="angle-left"
                    class="icon-flesh"
                    title="Déplacer la semaine"
                    @click="
                      handleDragWeek({
                        type: 'backward',
                        week: item,
                        month: value,
                        year: getCalenders.year
                      })
                    "
                  />
                </div>
                <div>{{ item | FormatWeek }}</div>
                <div>
                  <font-awesome-icon
                    v-if="index == key.length - 1 && value != 12"
                    icon="angle-right"
                    class="icon-flesh"
                    title="Déplacer la semaine"
                    @click="
                      handleDragWeek({
                        type: 'forward',
                        week: item,
                        month: value,
                        year: getCalenders.year
                      })
                    "
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
export default {
  data() {
    return {
      calenderAdd: null,
      ListYear: [],
      filteryear: null,
      error: null,
      loading: false
    };
  },
  methods: {
    ...mapActions(['AddCalendrier', 'getCallender', 'DragWeek']),
    resetModal() {
      this.calenderAdd = null;
      this.error = null;
      this.loading = false;
    },
    hideModal(ref) {
      this.$refs[ref].hide();
      this.resetModal();
    },
    async handleSubmitCalender() {
      this.loading = true;
      const response = await this.AddCalendrier(this.calenderAdd);
      if (response.success) {
        this.filteryear = this.calenderAdd;
        this.error = null;
        this.loading = false;
        this.hideModal('addCalenderModal');
      } else {
        this.error = response.error;
        this.loading = false;
      }
    },
    handleChangeYear() {
      this.getCallender(this.filteryear);
    },
    handleDragWeek(paayload) {
      this.DragWeek(paayload);
    }
  },
  computed: {
    ...mapGetters(['getLoadingCalender', 'getCalenders', 'getErrorCalender'])
  },
  components: {
    Card: () => import('../component/card.vue')
  },
  filters: {
    FormatMounths: value => {
      switch (value) {
        case '1':
          return 'Janvier';
        case '2':
          return 'Février';
        case '3':
          return 'Mars';
        case '4':
          return 'Avril';
        case '5':
          return 'Mai';
        case '6':
          return 'Juin';
        case '7':
          return 'Juillet';
        case '8':
          return 'Août';
        case '9':
          return 'Septembre';
        case '10':
          return 'Octobre';
        case '11':
          return 'Novembre';
        case '12':
          return 'Décembre';
        default:
          return value;
      }
    },
    FormatWeek: value => {
      switch (value) {
        case value:
          return value.replace('s', 'Semaine ');
        default:
          return value;
      }
    }
  },
  mounted() {
    var courantYear = moment().year();
    this.filteryear = courantYear;
    for (var i = 2019; i <= courantYear; i++) {
      this.ListYear.push({ value: i, text: i });
    }
    this.getCallender(this.filteryear);
  }
};
</script>

<style scoped lang="scss">
.setting-calender {
  width: 100%;

  .calender-body {
    margin-top: 5px;
    overflow-x: hidden;
    overflow-y: scroll;
    height: calc(100vh - 205px);
    .calender-card {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin: 7px;
      justify-content: center;
    }
  }
  .card-mounts {
    border: 1px solid rgba(108, 117, 125, 0.30196078431372547);
    margin-right: 8px;
    background-color: #332fc838;
    border-radius: 4px;
    display: table-cell;
    vertical-align: top;
    padding: 2px;
    margin: 5px;
    width: 120px;
    .card-mounths-header {
      position: relative;
      width: 100%;
      padding: 0px;
      font-weight: 600;
      font-size: 12px;
      font-family: 'Montserrat', sans-serif;
      text-align: center;
    }
    .card-mountes-body {
      .card-mounths-item {
        position: relative;
        overflow: visible;
        display: flex;
        justify-content: space-between;
        margin-top: 5px;
        border: 1px solid rgba(108, 117, 125, 0.3215686274509804);
        padding: 4px;
        background-color: rgb(245 244 250);
        width: 100%;
        border-radius: 4px;
        font-size: 11px;
        font-weight: 500;
        font-family: 'Montserrat', sans-serif;
        .icon-flesh {
          cursor: pointer;
          font-size: 12px;
          color: #0d0b94;
          &:hover {
            font-size: 13px;
          }
        }
      }
    }
  }
}
</style>
